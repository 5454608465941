import ReactDOM from "react-dom/client";
import "react-phone-number-input/style.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "../node_modules/toastr/build/toastr.min.css";
import Home from "./Home";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { fireGtmEvent } from "./utils/googleAnalytics";
import "./i18n";
import ErrorBoundry from "./ErrorBoundry";

const root = ReactDOM.createRoot(document.getElementById("root"));

const trackPerformance = (obj) => {
  fireGtmEvent(obj?.name, { perfomanceObj: obj });
};

function MainApp() {
  return (
    // <CacheBuster
    //   currentVersion={packageJson.version}
    //   isEnabled={true} //If false, the library is disabled.
    //   isVerboseMode={true} //If true, the library writes verbose logs to console.
    //   loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
    //   metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    // >
    <ErrorBoundry>
      <Provider store={store}>
        <Router>
          <Home />
        </Router>
      </Provider>
    </ErrorBoundry>
    // </CacheBuster>
  );
}
// const CacheClearedComponent = withClearCache(MainApp);
root.render(<MainApp />);
serviceWorkerRegistration.unregister();
reportWebVitals(trackPerformance);

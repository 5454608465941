import dayjs from "dayjs";
import React, { Component } from "react";
import { fireGtmEvent } from "./utils/googleAnalytics";

class ErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    fireGtmEvent("page_crashed", { error, info, errMsg: error?.message });
    console.error("ErrorBoundary caught an error", error, info);
    const lastReloaded = localStorage.getItem("last_error_reloaded");
    if (
      !lastReloaded ||
      dayjs(lastReloaded).isBefore(dayjs().subtract(10, "minutes"))
    ) {
      localStorage.setItem("last_error_reloaded", dayjs().toISOString());
      this.handleReload();
    }
    this.setState({
      hasError: true,
    });
  }

  handleReload = () => {
    window.location.reload(true);
    fireGtmEvent("errorboundary_reloaded");
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="errorBoundary">
          <h1>Something went wrong.</h1>
          <p>
            We're sorry for the inconvenience. Please try refreshing the page or
            reopening the tab.
          </p>
          <button onClick={() => window.location.reload()}>Reload Page</button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundry;

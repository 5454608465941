import React, { useEffect, useRef } from "react";
import "./Loader.css";
import { useTranslation } from "react-i18next";
const Loader = () => {
  // const video = useRef();

  // useEffect(() => {
  //   var isPlaying =
  //     video.current.currentTime > 0 &&
  //     !video.current.paused &&
  //     !video.current.ended &&
  //     video.current.readyState > video.current.HAVE_CURRENT_DATA;

  //   if (!isPlaying) {
  //     video.current.play();
  //   }
  //   return () => {
  //     video.current && video.current.pause();
  //   };
  // }, []);

  const { t } = useTranslation();

  return (
    <div className="coffeeLoader">
      <img
        src="https://ik.imagekit.io/hotelzifyprod/a2435c45-fcd3-4d98-9b7b-8b829898abc6.gif"
        alt="loader"
        width={100}
        height={100}
      />

      {/* <video
        loop
        width={100}
        type="video/webm"
        height={100}
        playsInline
        preload="auto"
        ref={video}
        muted
      >
        <source
          src="https://ik.imagekit.io/hotelzifyprod/a2b1e39f-6209-495e-85e1-33bb13c35fc9.webm"
          type="video/webm"
        />
      </video> */}

      <h1>{t("book_direct_best_price_service")}</h1>
      {/* <p>Found cheaper than this? We match that price! 😉</p> */}
    </div>
  );
};

export default Loader;

// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          modify_booking_title: "Modify Booking",
          Available: "Available",
          "Promotion Applied": "Promotion Applied",
          "Maximum room count reached!": "Maximum room count reached!",
          select_room: "Select Room",
          booking_summary: "Booking Summary",
          full_night_booking: "Full Night Booking",
          hourly_booking: "Hourly Booking",
          unlock: "Unlock",
          remove: "Remove",
          bed: "Bed",
          room: "Room",
          night: "Night",
          guests: "Guests",
          guest: "Guest",
          hour: "Hour",
          addons: "Addons",
          discount: "Discount",
          get_property_website: "Click here to get your property website",
          unlock_additional_discount: "Unlock Additional 15% OFF!",
          code_mount_applied: "Code MOUNT Applied",
          additional_15_off: "Additional Offer",
          final_price: "Final Price",
          taxes: "Taxes",
          book_now: "Book Now",
          hello_there: "Hello There!",
          im: "I'm",
          travel_dates_and_guests: "Travel dates and guests",
          your_host: "your host.",
          overview: "Overview",
          rooms: "Rooms",
          faqs: "FAQs",
          reviews: "Reviews",
          show_more: "show more",
          show_all: "Show all",
          map: "Map",
          facilities: "Facilities",
          unmarried_couples_not_allowed: "Unmarried couples are not allowed.",
          amenities: "Amenities",
          introducing_hourly_stays: "Introducing Hourly Stays!",
          show_less: "show less",
          book_for: "Book for",
          best_service_offers:
            "Book directly with us for best service and offers!",
          about_us: "About Us",
          book_for_full_night: "Book For Full Night",
          nearby_places: "Near By Places",
          view_in_maps: "View in Maps",
          couple_friendly:
            "Couple Friendly. Experience a safe and intimate stay.",
          slay_travel_itinerary: "Time to slay that travel itinerary",
          generate_in_seconds: "Generate one in seconds",
          cancel_booking: "Cancel your Booking",
          verify: "Verify",
          amount_paid: "Amount Paid",
          total_amount: "Total Amount",
          cancel_confirmation: "Are you sure you want to cancel?",
          refund_amount: "Refund Amount",
          no_option: "No",
          no_booking_found: "Sorry, We didn't find any booking for this number",
          yes_option: "Yes",
          property_rules_title: "Property Rules",
          selected_date: "Selected Date",
          select: "Select",
          faq_title: "Frequently Asked Questions",
          existing_booking_cancel:
            "Have an existing booking that you want to cancel?",
          cancellation_refund_policy: "Cancellation / Refund Policy",
          if_cancelled: "If cancelled",
          deducted: "of total amount will be deducted.",
          free_cancellation: "Free Cancellation Available",
          follow_us_on: "Follow us on",
          offer_ends_soon: "Offer Ends Soon, Book now to avail the offer",
          continue: "Continue",
          checkin_date_time: "Checkin Date - Checkin Time",
          checkin_checkout: "Checkin - Checkout",
          not_available_selected_checkin_time:
            "Not available for the selected checkin time",
          please_select_time_slot: "Please select time slot",
          reserve: "Reserve",
          adult_age: "18yrs and above",
          adults: "Adults",
          years: "years",
          children: "Children",
          infants: "Infants",
          selected_for: "Selected for",
          maximum_occupancy_reached:
            "Maximum occupancy reached, Select extra room.",
          guest_select_stay_for_other: "guest, please select stay for other",
          extra_adult: "Extra Adult",
          extra_child: "Extra Child",
          price_for_upto: "Price for upto",
          price_for: "Price for",
          max_bed: "Max Bed",
          bed_dorm: "Bed Dorm",
          additional_guests: "Additional Guests (chargeable)",
          max: "Max",
          max_pax: "Max Pax",
          included: "Included",
          free: "Free",
          verified: "Verified",
          can_stay_for_free: "can stay for free",
          lowest_price_ever: "Lowest Price Ever",
          recommended: "Recommended",
          min_pax: "Min Pax",
          free_cancellation_till: "Free Cancellation till",
          book_now_pay_later: "Book Now, Pay Later!",
          reserve_at_just: "Reserve at just",
          sold_out: "Sold Out",
          room_left_hurry_up: "Room left, Hurry Up!",
          off: "OFF",
          incl_of_taxes: "Incl of Taxes",
          select_rooms: "Select Rooms",
          add: "Add",
          enter_email_address: "Please Enter Email Address",
          view_room: "View Room",
          selected: "Selected",
          person: "Person",
          enter_valid_email_address: "Please Enter Valid Email Address",
          day: "Day",
          enter_name: "Please Enter Valid Name",
          enter_all_details: "Please enter all the details",
          please_select_payment_method: "Please select payment method",
          try_again_later: "Please try again later!",
          thank_you_response: "Thank you for your response :)",
          checkout_summary: "Checkout summary",
          check_in: "Check-in",
          room_and_guests: "Room and Guests",
          check_out: "Check-out",
          secured_by: "Secured by",
          more: "& more",
          pay_at_property: "Pay At Property",
          pay_just: "Pay just",
          pay_full_amount: "Pay full amount",
          to_book: "to book",
          book_now_pay_later: "Book Now Pay Later",
          cancel_booking_prompt: "Want to modify / cancel the booking?",
          bill_details: "Bill Details",
          additional_discount: "Additional Discount",
          select_payment_method: "Select Payment Method",
          cashback_offer: "Cashback upto ₹50 on online payments",
          add_reason_suggestions: "Please add your reason/suggestions",
          hurry_up_discount_expires_in:
            "Hurry Up! Additional Discount Expires in",
          selected_rooms: "Selected Rooms",
          pax_but_rooms_selected_for_only:
            "pax but rooms are selected for only",
          you_have_searched_for: "You've searched for",
          note: "Note:",
          please_book_extra_rooms_for_other:
            "Please book extra rooms for other",
          traveller_details: " Traveller",
          contact_number: "Contact number",
          enter_contact_number: "Enter Contact Number",
          full_name: "Full name",
          enter_traveller_name: "Enter Traveler Name",
          enter_age: "Enter Age",
          enter_email_id: "Email",
          estimated_arrival_time: "Estimated Arrival Time",
          enter_email: "Enter Email",
          type_your_requests_here: "Type your requests here",
          add_special_requests: "Add Special Requests",
          we_also_provide: "We also provide",
          cancellation_policy: "Cancellation Policy",
          date: "Date",
          refund: "Refund",
          refund_policy_description:
            "If the amount paid is less than the refund amount, no refund will be issued upon cancellation. Refund may take upto 4-5 business days to process.",
          before: "Before",
          why_book_direct: "Why Book Direct?",
          disclaimer: "Disclaimer",
          what_went_wrong: "What went wrong?",
          payment_options_message:
            "We provide all types of payment options including EMIs and wallets just like any other platform",
          booking_directly_message:
            "Booking directly with us saves on commissions lost, directly benefiting our property and staff who are the backbone of small businesses",
          book_directly_message:
            "Book directly with us to get best service quality",
          share_feedback: "Share feedback for a chance to",
          additional_discount_expires_in: "Additional Discount Expires in",
          win_voucher: "win an Amazon voucher worth ₹500",
          improvement_feedback: "Let us know how to improve",
          limited_offer: "Limited Offer!",
          click_here: "Click Here Now!",
          hourly_room_booking: "Hourly Room Booking",
          no_waiting_just_go: "No waiting, just go!",
          instant_confirmation: "Instant Confirmation",
          on_bookings_above: "On bookings above",
          get: "get",
          flat: "FLAT",
          and_get: "and get",
          early_bird_offer: "Early Bird Offer",
          use_code: "Use Code",
          book: "Book",
          last_minute_deals: "Last Minute Deals",
          days_advance: "days advance",
          book_now_for: "Book Now for",
          go_back: "Go Back",
          room_details: "Room Details",
          page_not_found: "Page Not Found",
          above_12_years_age: "Above 12 years of Age",
          select_guests: "Select Guests",
          below_12_years_age: "Below 12 years of Age",
          done: "Done",
          fill_details_generate_itinerary:
            "Fill in the following details to generate a personalised travel itinerary for your trip",
          plan_up_to_3_days: "You can only plan up to 3 days",
          fill_all_details: "Please fill all the details",
          maximum_additional_guest: "Maximum additional guest:",
          room_minimum_capacity: "Room minimum capacity:",
          no_worries_cancel: "No worries. Cancel hassle-free.",
          flexible_hassle_free_affordable: "Flexible. Hassle-free. Affordable.",
          wait_create_itinerary:
            "Please wait while we create your personalised travel itinerary",
          generate_travel_itinerary: "Generate Travel Itinerary",
          total: "Total",
          sold_out: "Sold Out",
          secured_payments: "100% secured payments",
          email: "Email",
          generate_itinerary: "Generate Itinerary",
          planning_days: "How many days are you planning for?",
          enter_number_of_days: "Enter number of days",
          purpose_of_visit: "What is the purpose of the visit?",
          visit_purposes: "Vacation, Leisure, Culinary Delights etc",
          personalized_itinerary: "Here's your personalized trip itinerary",
          book_now_savor_itinerary: "Book now, savor the itinerary!",
          book_direct_best_price_service:
            "Book Direct: Best Price, Exceptional Service.",
          subscribe_now_to: "Subscribe now to",
          get_extra: "Get extra",
          subscribe: "Subscribe",
          no_thanks: "No, Thanks!",
          add_for: "Add for",
          is_selected_for: "is selected for",
          days: "days",
          powered_by: "Powered by",
          copyrights: "Copyrights",
          privacy_policy: "Privacy Policy",
          terms_and_conditions: "Terms and Conditions",
          made_with_love_in_india: "Made with ❤️ in India",
          all_rights_reserved: "All Rights Reserved",
          please_reach_us_at: "Please reach us at",
          ask_a_question: "Ask a Question?",
          applied: "Applied",
          for: "for",
          enter_valid_phone_number: "Enter valid phone number",
          upto: "Upto",
          nights: "nights",
          per_night: "per night",
          booking_support_local:
            "By booking directly, you support our local hotel and the community. Join us in making a positive impact and experience genuine local hospitality.",
          you_got: "You got",
          enhanced_experience: "Enhanced Experience",
          first_choice_preference: "First Choice Preference",
          book_directly_lowest_rates:
            "Book directly for our lowest rates, guaranteed. Get the best value for your money by reserving through our website.",
          booking_directly_saves:
            "By booking directly with us, we can save on the 20% commission charges and hence provide you a better experience.",
          book_directly_hassle_free:
            "Book directly with our official hotel website for a hassle-free experience and unmatched benefits!",
          best_price_guarantee: "Best Price Guarantee",
          support_local_businesses: "Support Local Businesses",
          booking_benefits: "Benefits of Booking from our Official Website",
          please_enter_your_question: "Please enter your question",
          select_at_least_one_room: "Please select at least one room",
          additional_15_percent_off_applied: "Additional 15% OFF applied!",
          book_direct_choose_room:
            "Book directly for the privilege of choosing your ideal room first.",
          lets_book: "Let's Book",
          help_us_know_more: "Help us know more!",
          help_us_know_more: "Help us know more!",
          get_best_price: "Get Best Price!",
          submit: "Submit",
          enter_booking_id: "Please enter booking id",
          on_google: "on Google",
          check_inbox_spam_folder:
            "check your inbox and spam folder for the booking voucher we sent via email and SMS. Contact us if you don't receive an update within 6 hours.",
          better_price_elsewhere: "Found a better price elsewhere?",
          share_details_best_deal:
            "Share your details for the best deal! Our team will reach out.",
          best_price_guarantee_chat: "Video call to see your room!",
          apology_incorrect_price:
            "We sincerely apologize for the inconvenience caused by the incorrect price displayed for your booking on our website. It was an unfortunate occurrence due to high demand and a technical glitch. We deeply regret any inconvenience caused and want to make things right for you",
          booking_cancelled_successfully: "Booking Cancelled Successfully",
          where_lower_price: "Where did you come across the lower price?",
          booking_request_received: "We have received your booking request!",
          complete_booking: "We are holding your room!",
          booking_confirmed: "Booking Confirmed!",
          booking_rejected: "Booking Rejected!",
          booking_cancelled: "Booking Cancelled!",
          your_reservation_has_been_marked_no_show_by_the_property: `Your reservation has been marked as "No Show" by the property.`,
          waiting_to_host_your_stay: "Please complete the booking :)",
          enjoy_your_stay: "Congratulations! Enjoy your stay :)",
          stays_not_available:
            "Sorry, Stays are not available for the selected days.",
          booking_cancelled_message: "You've cancelled your booking",
          refund_initiated: "Refund is initiated. (If Applicable)",
          booking_cancelled_notification: "Your booking has been cancelled",
          refund_initiated_notification:
            "and refund is initiated. (If Applicable)",
          booking_details: "Booking Details",
          contact_us: "Contact Us",
          check_in: "Check-In",
          room_and_guest: "ROOM & GUEST",
          child: "Child",
          infant: "Infant",
          payment_status: "PAYMENT STATUS",
          decline_price_refund_note:
            "Note: If you decide to decline the revised price, we will process a refund for any amount you have already paid within 3-5 working days.",
          view_details: "view details",
          updated_price: "Updated Price",
          amount_to_pay: "Amount To Pay (Incl. of all taxes)",
          advance_paid: "Advance Paid",
          location: "Location",
          open_in_maps: "Open in Maps",
          payment_options: "Payment Options",
          now_to_reserve: "now to reserve",
          reject_action: "Reject",
          call_hotel: "Call Hotel",
          accept_action: "Accept",
          complete_bookings: "Complete Booking",
          go_home: "Go Home",
          refund_details: "Refund Details",
          booking_id: "Booking ID",
          disclaimer_text:
            "Hotelzify Private Limited (“Hotelzify”) generates and maintains this Website on behalf of the Property and merely enables technological support for booking a particular property. All information available on the Website is as per the information provided by the Property to Hotelzify and is for reference only. Any discrepancies, or costs, losses, damages, lawsuits, deficiencies, claims and expenses (including court costs and reasonable attorney’s fees) in connection with or arising out of or resulting from or incidental to this Website or bookings made on it, shall be raised by the User with the Property directly. Hotelzify will have no responsibility in the process of resolution, and shall not take any liability for such discrepancies and/or losses.",
          age_optional: "Age (optional)",
          payment_methods: "Select Mode Of Payment",
          proceed: "Proceed",
          change_language: "Change Language",
          booking_expired_or_not_exist:
            "Booking is either expired or does not exist. Please try creating a booking again.",
          invalid_date_redirect: "Invalid Date! Redirecting to home page",
          cashback_offer_mobiqwik: "Assured ₹10-50 Cashback!",
          use_mobikwik: "Extra Discount via Mobikwik",
          "Video call to see your room": "Video call to see your room",
          "Call Property": "Call Property",
          "Incase of No Show / Cancelling on day of Checkin will incur":
            "Incase of No Show / Cancelling on day of Checkin will incur",
          penalty: "penalty",
          Cancelling: "Cancelling",
          "before arrival incurs a": "before arrival incurs a",
        },
      },
      de: {
        translation: {
          modify_booking_title: "Buchungstitel ändern",
          "before arrival incurs a": "vor Ankunft fällt eine an",
          Cancelling: "absagen",
          penalty: "Strafe",
          "Incase of No Show / Cancelling on day of Checkin will incur":
            "Bei Nichterscheinen / Stornierung am Tag des Check-ins wird",
          Available: "Verfügbar",
          "Promotion Applied": "Aktion angewendet",
          "Maximum room count reached!": "Maximale Zimmeranzahl erreicht!",
          "Call Property": "Eigentum anrufen",
          "Video call to see your room": "Videoanruf, um Ihr Zimmer zu sehen",
          use_mobikwik: "Bezahlen Sie mit Mobikwik Wallet und",
          cashback_offer_mobiqwik: "Erhalten Sie pauschal 10 - 50 ₹ Cashback!",
          proceed: "Weiter",
          change_language: "Sprache ändern",
          payment_methods: "Zahlungsmethoden",
          age_optional: "Alter (optional)",
          invalid_date_redirect:
            "Ungültiges Datum! Weiterleitung zur Startseite",
          booking_id: "Buchungsnummer",
          go_home: "Zurück zur Startseite",
          booking_expired_or_not_exist:
            "Die Buchung ist entweder abgelaufen oder existiert nicht. Bitte versuchen Sie erneut, eine Buchung zu erstellen.",
          complete_booking: "Buchung abschließen",
          refund_details: "Rückerstattungsdetails",
          accept_action: "Akzeptieren",
          call_hotel: "Hotel anrufen",
          reject_action: "Ablehnen",
          now_to_reserve: "jetzt reservieren",
          open_in_maps: "In Karten öffnen",
          payment_options: "Zahlungsoptionen",
          cancel_booking_prompt: "Möchten Sie die Buchung stornieren?",
          location: "Ort",
          decline_price_refund_note:
            "Hinweis: Wenn Sie sich entscheiden, den überarbeiteten Preis abzulehnen, werden wir eine Rückerstattung für den bereits gezahlten Betrag innerhalb von 3-5 Werktagen bearbeiten.",
          advance_paid: "Vorauszahlung",
          updated_price: "Aktualisierter Preis",
          amount_to_pay: "Zu zahlender Betrag (inkl. aller Steuern)",
          view_details: "Details anzeigen",
          payment_status: "ZAHLUNGSSTATUS",
          child: "Kind",
          infant: "Säugling",
          room_and_guest: "ZIMMER & GÄSTE",
          check_in: "Check-In",
          contact_us: "Kontaktieren Sie uns",
          booking_details: "Buchungsdetails",
          refund_initiated_notification:
            "und die Rückerstattung wird eingeleitet. (falls zutreffend)",
          booking_cancelled_notification: "Ihre Buchung wurde storniert",
          booking_cancelled: "Buchung storniert!",
          booking_cancelled_message: "Sie haben Ihre Buchung storniert",
          refund_initiated:
            "Rückerstattung wird eingeleitet. (falls zutreffend)",
          stays_not_available:
            "Entschuldigung, Aufenthalte sind für die ausgewählten Tage nicht verfügbar.",
          booking_rejected: "Buchung abgelehnt!",
          enjoy_your_stay:
            "Herzlichen Glückwunsch! Genießen Sie Ihren Aufenthalt :)",
          booking_confirmed: "Buchung bestätigt!",
          waiting_to_host_your_stay:
            "Wir freuen uns darauf, Ihren Aufenthalt zu begrüßen :)",
          complete_booking: "Buchen abschließen!",
          check_inbox_spam_folder:
            "checken Sie Ihren Posteingang und Spam-Ordner für den Buchungsgutschein, den wir per E-Mail und SMS gesendet haben. Kontaktieren Sie uns, wenn Sie innerhalb von 6 Stunden kein Update erhalten.",
          booking_request_received: "Wir haben Ihre Buchungsanfrage erhalten!",
          apology_incorrect_price:
            "Wir entschuldigen uns aufrichtig für die Unannehmlichkeiten, die durch den falschen Preis verursacht wurden, der für Ihre Buchung auf unserer Website angezeigt wurde. Es handelte sich um ein bedauerliches Ereignis aufgrund hoher Nachfrage und eines technischen Problems. Wir bedauern aufrichtig etwaige Unannehmlichkeiten und möchten die Dinge für Sie in Ordnung bringen.",
          enter_booking_id: "Bitte geben Sie die Buchungs-ID ein",
          booking_cancelled_successfully: "Buchung erfolgreich storniert",
          submit: "Absenden",
          best_price_guarantee_chat:
            "Videoanruf, um Ihr Zimmer zu besichtigen.",
          on_google: "auf Google",
          get_best_price: "Bekommen Sie den besten Preis!",
          where_lower_price: "Wo haben Sie den niedrigeren Preis gefunden?",
          help_us_know_more: "Helfen Sie uns mehr zu erfahren!",
          help_us_know_more: "Helfen Sie uns mehr zu erfahren!",
          share_details_best_deal:
            "Teilen Sie Ihre Daten für das beste Angebot! Unser Team wird sich bei Ihnen melden.",
          lets_book: "Jetzt buchen",
          better_price_elsewhere: "Einen besseren Preis anderswo gefunden?",
          book_directly_hassle_free:
            "Buchen Sie direkt über unsere offizielle Hotelwebsite für ein unkompliziertes Erlebnis und unvergleichliche Vorteile!",
          book_direct_choose_room:
            "Buchen Sie direkt, um das Privileg zu haben, Ihr ideales Zimmer als Erster auszuwählen.",
          first_choice_preference: "Erstwahlpräferenz",
          booking_directly_saves:
            "Durch direkte Buchung bei uns können wir die 20% Provision sparen und Ihnen daher ein besseres Erlebnis bieten.",
          enhanced_experience: "Verbessertes Erlebnis",
          booking_support_local:
            "Durch direkte Buchung unterstützen Sie unser lokales Hotel und die Gemeinschaft. Schließen Sie sich uns an, um einen positiven Einfluss zu haben und echte lokale Gastfreundschaft zu erleben.",
          support_local_businesses: "Unterstützen Sie lokale Unternehmen",
          book_directly_lowest_rates:
            "Buchen Sie direkt für unsere garantiert niedrigsten Preise. Erhalten Sie das beste Preis-Leistungs-Verhältnis, indem Sie über unsere Website reservieren.",
          for: "für",
          best_price_guarantee: "Bestpreisgarantie",
          per_night: "pro Nacht",
          booking_benefits:
            "Vorteile der Buchung über unsere offizielle Website",
          nights: "Nächte",
          upto: "Bis zu",
          you_got: "Du hast",
          additional_15_percent_off_applied:
            "Zusätzliche 15% Rabatt angewendet!",
          applied: "Angewendet",
          select_at_least_one_room: "Bitte wählen Sie mindestens ein Zimmer",
          ask_a_question: "Frage stellen?",
          please_enter_your_question: "Bitte geben Sie Ihre Frage ein",
          please_reach_us_at: "Bitte kontaktieren Sie uns unter",
          all_rights_reserved: "Alle Rechte vorbehalten",
          copyrights: "Urheberrechte",
          made_with_love_in_india: "Hergestellt mit ❤️ in Indien",
          powered_by: "Angetrieben von",
          terms_and_conditions: "Geschäftsbedingungen",
          privacy_policy: "Datenschutzrichtlinie",
          days: "Tage",
          is_selected_for: "ist ausgewählt für",
          no_thanks: "Nein, Danke!",
          add_for: "Hinzufügen für",
          subscribe: "Abonnieren",
          get_extra: "Erhalten Sie zusätzlich",
          subscribe_now_to: "Abonnieren Sie jetzt",
          book_direct_best_price_service:
            "Buche Direkt. Zum besten Preis. Mit bestem Service",
          secured_payments: "100% sichere Zahlungen",
          book_now_savor_itinerary: "Jetzt buchen, den Reiseplan genießen!",
          email: "E-Mail",
          personalized_itinerary: "Hier ist Ihr personalisierter Reiseplan",
          generate_itinerary: "Reiseplan erstellen",
          visit_purposes: "Urlaub, Freizeit, kulinarische Genüsse etc",
          purpose_of_visit: "Was ist der Zweck des Besuchs?",
          enter_number_of_days: "Geben Sie die Anzahl der Tage ein",
          planning_days: "Für wie viele Tage planen Sie?",
          sold_out: "Ausverkauft",
          total: "Gesamt",
          fill_details_generate_itinerary:
            "Füllen Sie die folgenden Angaben aus, um einen personalisierten Reiseplan für Ihre Reise zu erstellen",
          generate_travel_itinerary: "Reiseplan erstellen",
          wait_create_itinerary:
            "Bitte warten Sie, während wir Ihren persönlichen Reiseplan erstellen",
          fill_all_details: "Bitte füllen Sie alle Angaben aus",
          plan_up_to_3_days: "Sie können nur bis zu 3 Tage planen",
          done: "Fertig",
          below_12_years_age: "Unter 12 Jahre alt",
          select_guests: "Gäste auswählen",
          above_12_years_age: "Über 12 Jahre alt",
          room_details: "Zimmerdetails",
          maximum_additional_guest: "Maximal zusätzlicher Gast:",
          go_back: "Zurück",
          room_minimum_capacity: "Mindestkapazität des Zimmers:",
          page_not_found: "Seite nicht gefunden",
          book: "Buchen",
          book_now_for: "Jetzt buchen für",
          last_minute_deals: "Last-Minute-Angebote",
          early_bird_offer: "Frühbucherangebot",
          flat: "FLACH",
          and_get: "und erhalten",
          use_code: "Code verwenden",
          get: "erhalten",
          days_advance: "Tage im Voraus",
          on_bookings_above: "Bei Buchungen über",
          no_worries_cancel: "Keine Sorgen. Stornierung ohne Probleme.",
          instant_confirmation: "Sofortige Bestätigung",
          no_waiting_just_go: "Kein Warten, einfach los!",
          flexible_hassle_free_affordable:
            "Flexibel. Stressfrei. Erschwinglich.",
          hourly_room_booking: "Stundenweise Zimmerbuchung",
          click_here: "Klicken Sie hier jetzt!",
          limited_offer: "Begrenztes Angebot!",
          improvement_feedback:
            "Lassen Sie uns wissen, wie wir uns verbessern können",
          win_voucher: "Gewinnen Sie einen Amazon-Gutschein im Wert von ₹500",
          share_feedback: "Teilen Sie Ihr Feedback für eine Chance,",
          disclaimer: "Haftungsausschluss",
          what_went_wrong: "Was ist schief gelaufen?",
          payment_options_message:
            "Wir bieten alle Arten von Zahlungsoptionen einschließlich Ratenzahlungen und Geldbörsen wie jede andere Plattform",
          booking_directly_message:
            "Die direkte Buchung bei uns spart verlorene Provisionen und kommt direkt unserer Immobilie und unserem Personal zugute, die das Rückgrat kleiner Unternehmen bilden",
          date: "Datum",
          book_directly_message:
            "Buchen Sie direkt bei uns, um die beste Servicequalität zu erhalten",
          before: "Vor",
          why_book_direct: "Warum direkt buchen?",
          refund: "Rückerstattung",
          we_also_provide: "Wir bieten auch",
          cancellation_policy: "Stornierungsbedingungen",
          additional_discount_expires_in: "Zusätzlicher Rabatt läuft ab in",
          add_special_requests: "Spezielle Anfragen hinzufügen",
          enter_email_id: "E-Mail-ID",
          type_your_requests_here: "Geben Sie hier Ihre Anfragen ein",
          estimated_arrival_time: "Voraussichtliche Ankunftszeit",
          enter_email: "E-Mail eingeben",
          enter_traveller_name: "Geben Sie den Namen des Reisenden ein",
          full_name: "Vollständiger Name",
          enter_age: "Geben Sie das Alter ein",
          traveller_details: "Reisedaten",
          contact_number: "Kontaktnummer",
          enter_contact_number: "Geben Sie die Kontaktnummer ein",
          note: "Hinweis:",
          refund_policy_description:
            "Wenn der gezahlte Betrag weniger als der Rückerstattungsbetrag ist, wird bei Stornierung keine Rückerstattung ausgegeben. Die Rückerstattung kann bis zu 4-5 Werktage dauern, um bearbeitet zu werden.",
          bill_details: "Rechnungsdetails",
          please_book_extra_rooms_for_other:
            "Bitte buchen Sie zusätzliche Zimmer für andere",
          pax_but_rooms_selected_for_only:
            "Personen, aber Zimmer sind nur ausgewählt für",
          you_have_searched_for: "Sie haben gesucht nach",
          selected_rooms: "Ausgewählte Zimmer",
          additional_discount: "Zusätzlicher Rabatt",
          book_now_pay_later: "Jetzt buchen, später bezahlen",
          to_book: "zu buchen",
          pay_just: "Zahlen Sie einfach",
          hurry_up_discount_expires_in:
            "Beeil dich! Zusätzlicher Rabatt läuft ab in",
          pay_at_property: "Bezahlen Sie bei der Unterkunft",
          pay_full_amount: "Vollen Betrag zahlen",
          secured_by: "Gesichert durch",
          more: "& mehr",
          cashback_offer: "Cashback bis zu ₹50 bei Online-Zahlungen",
          select_payment_method: "Zahlungsmethode auswählen",
          check_in: "Einchecken",
          room_and_guests: "Zimmer und Gäste",
          check_out: "Auschecken",
          checkout_summary: "Zusammenfassung der Kasse",
          thank_you_response: "Vielen Dank für Ihre Antwort :)",
          add_reason_suggestions:
            "Bitte fügen Sie Ihren Grund/Vorschläge hinzu",
          try_again_later: "Bitte versuchen Sie es später erneut!",
          please_select_payment_method:
            "Bitte wählen Sie die Zahlungsmethode aus",
          enter_all_details: "Bitte geben Sie alle Details ein",
          enter_name: "Bitte geben Sie einen Namen ein",
          enter_valid_email_address:
            "Bitte geben Sie eine gültige E-Mail-Adresse ein",
          enter_email_address: "Bitte geben Sie eine E-Mail-Adresse ein",
          enter_valid_phone_number:
            "Bitte geben Sie eine gültige Telefonnummer ein",
          day: "Tag",
          person: "Person",
          sold_out: "Ausverkauft",
          view_room: "Zimmer anzeigen",
          add: "Hinzufügen",
          selected: "Ausgewählt",
          select_rooms: "Zimmer auswählen",
          incl_of_taxes: "Inklusive Steuern",
          off: "RABBAT",
          disclaimer_text:
            "Hotelzify Private Limited (“Hotelzify”) erstellt und pflegt diese Website im Auftrag des Hotels und ermöglicht lediglich technische Unterstützung für die Buchung eines bestimmten Hotels. Alle Informationen, die auf der Website verfügbar sind, entsprechen den Informationen, die dem Hotelzify vom Hotel zur Verfügung gestellt wurden, und dienen nur zu Referenzzwecken. Alle Unstimmigkeiten, Kosten, Verluste, Schäden, Klagen, Mängel, Ansprüche und Ausgaben (einschließlich Gerichtskosten und angemessener Anwaltsgebühren) im Zusammenhang mit dieser Website oder Buchungen, die auf ihr getätigt wurden, müssen vom Benutzer direkt beim Hotel geltend gemacht werden. Hotelzify übernimmt keine Verantwortung für den Lösungsprozess und haftet nicht für solche Unstimmigkeiten und/oder Verluste.",
          room_left_hurry_up: "Zimmer übrig, Beeil dich!",
          reserve_at_just: "Reservieren Sie jetzt für nur",
          book_now_pay_later: "Jetzt buchen, später bezahlen!",
          verified: "Verifiziert",
          free_cancellation_till: "Kostenlose Stornierung bis",
          min_pax: "Mindestanzahl Personen",
          recommended: "Empfohlen",
          lowest_price_ever: "Niedrigster Preis aller Zeiten",
          can_stay_for_free: "kann kostenlos übernachten",
          included: "Inbegriffen",
          free: "Kostenlos",
          max_pax: "Max. Personen",
          max: "Max",
          additional_guests: "Zusätzliche Gäste (gebührenpflichtig)",
          max_bed: "Maximale Betten",
          bed_dorm: "Bett Schlafsaal",
          price_for: "Preis für",
          price_for_upto: "Preis für bis zu",
          extra_child: "Zusätzliches Kind",
          extra_adult: "Zusätzlicher Erwachsener",
          guest_select_stay_for_other:
            "Gast, bitte wählen Sie einen Aufenthalt für andere",
          selected_for: "Ausgewählt für",
          children: "Kinder",
          infants: "Säuglinge",
          years: "Jahre",
          adult_age: "18 Jahre und älter",
          adults: "Erwachsene",
          maximum_occupancy_reached:
            "Maximale Belegung erreicht, wählen Sie ein zusätzliches Zimmer.",
          reserve: "Reservieren",
          not_available_selected_checkin_time:
            "Für die ausgewählte Check-in-Zeit nicht verfügbar",
          checkin_date_time: "Check-in-Datum – Check-in-Zeit",
          checkin_checkout: "Einchecken - Auschecken",
          please_select_time_slot: "Bitte wählen Sie einen Zeitraum",
          continue: "Weiter",
          additional_15_off: "Zusätzliche Rabatt!",
          offer_ends_soon:
            "Angebot endet bald, buchen Sie jetzt, um das Angebot zu nutzen",
          select: "Auswählen",
          follow_us_on: "Folgen Sie uns auf",
          free_cancellation: "Kostenlose Stornierung möglich",
          deducted: "des Gesamtbetrags wird abgezogen.",
          if_cancelled: "Wenn storniert",
          cancellation_refund_policy:
            "Stornierungs- / Rückerstattungsrichtlinie",
          cancel_booking: "Buchung stornieren",
          existing_booking_cancel:
            "Haben Sie eine bestehende Buchung, die Sie stornieren möchten?",
          yes_option: "Ja",
          selected_date: "Ausgewähltes Datum",
          property_rules_title: "Hausregeln",
          faq_title: "Häufig gestellte Fragen",
          no_booking_found:
            "Entschuldigung, wir haben keine Buchung für diese Nummer gefunden",
          no_option: "Nein",
          verify: "Überprüfen",
          amount_paid: "Bezahlter Betrag",
          cancel_confirmation: "Sind Sie sicher, dass Sie stornieren möchten?",
          nearby_places: "In der Nähe",
          total_amount: "Gesamtbetrag",
          refund_amount: "Rückerstattungsbetrag",
          about_us: "Über uns",
          view_in_maps: "In Karten anzeigen",
          generate_in_seconds: "Generiere einen in Sekunden",
          slay_travel_itinerary: "Es ist Zeit, diesen Reiseplan zu meistern",
          book_for: "Buchen für",
          best_service_offers:
            "Buchen Sie direkt bei uns für besten Service und Angebote!",
          introducing_hourly_stays: "Einführung von Stundenbuchungen!",
          book_for_full_night: "Für die ganze Nacht buchen",
          show_all: "Alle anzeigen",
          show_less: "weniger anzeigen",
          couple_friendly:
            "Paare willkommen. Erleben Sie einen sicheren und intimen Aufenthalt.",
          facilities: "Einrichtungen",
          overview: "Überblick",
          unmarried_couples_not_allowed:
            "Unverheiratete Paare sind nicht erlaubt.",
          show_more: "mehr anzeigen",
          rooms: "Zimmer",
          faqs: "FAQs",
          map: "Karte",
          amenities: "Annehmlichkeiten",
          travel_dates_and_guests: "Reisedaten und Gäste",
          your_host: "Ihr Gastgeber.",
          im: "Ich bin",
          reviews: "Bewertungen",
          hello_there: "Hallo!",
          final_price: "Endpreis",
          book_now: "Jetzt buchen",
          code_mount_applied: "Code MOUNT angewendet",
          hour: "Stunde",
          taxes: "Steuern",
          discount: "Rabatt",
          addons: "Zusatzleistungen",
          guest: "Gast",
          night: "Nacht",
          remove: "Entfernen",
          guests: "Gäste",
          room: "Zimmer",
          bed: "Bett",
          unlock: "Freischalten",
          unlock_additional_discount:
            "Zusätzlichen Rabatt von 15% freischalten!",
          select_room: "Zimmer auswählen",
          hourly_booking: "Stundenweise Buchung",
          full_night_booking: "Ganze-Nacht-Buchung",
          booking_summary: "Buchungszusammenfassung",
          get_property_website:
            "Klicken Sie hier, um Ihre Immobilienwebsite zu erhalten",
        },
      },
      ar: {
        translation: {
          modify_booking_title: "تعديل عنوان الحجز",
          "before arrival incurs a": "قبل الوصول يترتب عليه",
          Cancelling: "إلغاء",
          penalty: "عقوبة",
          "Incase of No Show / Cancelling on day of Checkin will incur":
            "في حالة عدم الحضور / إلغاء يوم تسجيل الوصول سيتحمل",
          Available: "متاح",
          "Promotion Applied": "تم تطبيق العرض",
          "Maximum room count reached!":
            "تم الوصول إلى الحد الأقصى لعدد الغرف!",
          select_room: "اختر الغرفة",
          booking_summary: "تفاصيل الحجز",
          full_night_booking: "حجز ليلة كاملة",
          hourly_booking: "حجز بالساعة",
          unlock: "فتح",
          remove: "إزالة",
          bed: "سرير",
          room: "غرفة",
          night: "ليلة",
          guests: "نزلاء",
          guest: "نزيل",
          hour: "ساعة ",
          addons: "الإضافات",
          discount: "خصم",
          get_property_website: "اضغط هنا للحصول على موقع الفندق الخاص بك",
          unlock_additional_discount: "احصل على خصم إضافي بنسبة 15%",
          code_mount_applied: 'الرمز "MOUNT" تم تطبيقه',
          additional_15_off: "عرض إضافي",
          final_price: "السعر النهائي",
          taxes: "الضريبة",
          book_now: "احجز الآن",
          hello_there: "أهلاً بك!",
          im: "أنا",
          travel_dates_and_guests: "تاريخ السفر وعدد الضيوف",
          your_host: "مضيفك",
          overview: "نظرة عامة",
          rooms: "الغرف",
          faqs: "أسئلة شائعة",
          reviews: "التقييمات",
          show_more: "عرض المزيد",
          show_all: "عرض الكل",
          map: "الخريطة",
          facilities: "المرافق",
          unmarried_couples_not_allowed:
            "لا يُسمح للثنائي غير المتزوج بالدخول.",
          amenities: "المرافق",
          introducing_hourly_stays: "الإقامة بالساعة متاحة الآن!",
          show_less: "عرض أقل",
          book_for: "احجز لـ",
          best_service_offers: "احجز مباشرةً معنا لأفضل الخدمات والعروض!",
          about_us: "معلومات عنا",
          book_for_full_night: "احجز لليلة كاملة",
          nearby_places: "المعالم المحيطة بالفندق",
          view_in_maps: "عرض الخريطة",
          couple_friendly: "مناسب للأزواج. استمتع بإقامة آمنة ومريحة.",
          slay_travel_itinerary: "حان الوقت لتنظيم خطة السفر!",
          generate_in_seconds: "أنشئ واحدة في ثوانٍ",
          cancel_booking: "إلغاء الحجز",
          verify: "تحقق",
          amount_paid: "المبلغ المدفوع",
          total_amount: "المبلغ الإجمالي",
          cancel_confirmation: "هل أنت متأكد من رغبتك في الإلغاء؟",
          refund_amount: "قيمة الاسترداد",
          no_option: "لا",
          no_booking_found: "عذرًا، لم نعثر على أي حجز لهذا الرقم.",
          yes_option: "نعم",
          property_rules_title: "قواعد الفندق",
          selected_date: "التاريخ المحدد",
          select: "اختر",
          faq_title: "الأسئلة الشائعة",
          existing_booking_cancel: "هل لديك حجز تريد إلغائه؟",
          cancellation_refund_policy: "سياسة الإلغاء / الاسترداد",
          if_cancelled: "في حالة الإلغاء",
          deducted: "من المبلغ الإجمالي سيتم خصمه",
          free_cancellation: "إمكانية الإلغاء المجاني",
          follow_us_on: "تابعنا على",
          offer_ends_soon: "العرض سينتهي قريبًا، احجز الآن للاستفادة من العرض!",
          continue: "المتابعة",
          checkin_date_time: "تاريخ الدخول - وقت الدخول",
          checkin_checkout: "تسجيل الوصول - تسجيل الخروج",
          not_available_selected_checkin_time:
            "غير متوفر في وقت تسجيل الدخول المحدد",
          please_select_time_slot: "يرجى اختيار فترة زمنية",
          reserve: "احجز الآن",
          adult_age: "18 عامًا وما فوق",
          adults: "البالغين",
          years: "سنوات",
          children: "الأطفال",
          infants: "الرضع",
          selected_for: "محدد لـ",
          maximum_occupancy_reached:
            "تم الوصول إلى الحد الأقصى لعدد النزلاء، يرجى اختيار غرفة إضافية",
          guest_select_stay_for_other:
            "عزيزي الضيف، يرجى تحديد  فترةالإقامة للضيوف الآخرين.",
          extra_adult: "شخص بالغ إضافي",
          extra_child: "طفل إضافي",
          price_for_upto: "السعر حتى",
          price_for: "السعر لـ",
          max_bed: "أقصى عدد من الأسرّة",
          bed_dorm: "سرير في غرفة مشتركة",
          additional_guests: "نزلاء إضافيون (رسوم إضافية)",
          max: "الحد الأقصى",
          max_pax: "الحد الأقصى للضيوف",
          included: "مشمول",
          free: "مجاني",
          verified: "تم التحقق",
          can_stay_for_free: "يمكن الإقامة مجانًا",
          lowest_price_ever: "أقل سعر على الإطلاق",
          recommended: "موصى به",
          min_pax: "الحد الأدنى للضيوف",
          free_cancellation_till: "إلغاء مجاني حتى",
          book_now_pay_later: "احجز الآن وادفع لاحقًا",
          reserve_at_just: "احجز الآن بسعر فقط",
          sold_out: "تم البيع بالكامل",
          room_left_hurry_up: "غرفة واحدة متبقية، سارع بالحجز!",
          off: "خصم",
          incl_of_taxes: "شامل الضريبة",
          select_rooms: "اختر الغرف",
          add: "إضافة",
          enter_email_address: "يرجى إدخال البريد الإلكتروني ",
          view_room: "عرض الغرفة",
          selected: "محدد",
          person: "شخص",
          enter_valid_email_address: "يرجى إدخال بريد إلكتروني صحيح",
          day: "يوم",
          enter_name: "يرجى إدخال اسم صحيح",
          enter_all_details: "يرجى إدخال جميع التفاصيل",
          please_select_payment_method: "يرجى اختيار طريقة الدفع المناسبة",
          try_again_later: "يرجى إعادة المحاولة لاحقًا!",
          thank_you_response: "شكرًا لردك :)",
          checkout_summary: "تفاصيل الخروج",
          check_in: "تسجيل الدخول",
          room_and_guests: "الغرف والنزلاء",
          check_out: "تسجيل الخروج",
          secured_by: "محمي بواسطة",
          more: "والمزيد",
          pay_at_property: "الدفع عند الوصول",
          pay_just: "ادفع فقط",
          pay_full_amount: "ادفع القيمة كاملة",
          to_book: "لإتمام الحجز",
          cancel_booking_prompt: "هل تريد إلغاء الحجز؟",
          bill_details: "تفاصيل الفاتورة",
          additional_discount: "خصم إضافي",
          select_payment_method: "اختر طريقة الدفع المناسبة",
          cashback_offer: "كاش باك يصل إلى 50 عند الدفع الإلكتروني",
          add_reason_suggestions: "يرجى تقديم أسبابك/اقتراحاتك",
          hurry_up_discount_expires_in: "أسرع! الخصم الإضافي ينتهي خلال",
          selected_rooms: "الغرف المحددة",
          pax_but_rooms_selected_for_only: "عدد النزلاء لكن الغرف متاحة لـ",
          you_have_searched_for: "لقد بحثت عن",
          note: "ملاحظة:",
          please_book_extra_rooms_for_other:
            "يرجى حجز غرف إضافية للأفراد الآخرين",
          traveller_details: "بيانات المسافر",
          contact_number: "رقم التواصل",
          enter_contact_number: "يرجى إدخال رقم التواصل",
          full_name: "الاسم الكامل",
          enter_traveller_name: "يرجى إدخال اسم المسافر",
          enter_age: "يرجى إدخال العمر",
          enter_email_id: "البريد الإلكتروني",
          estimated_arrival_time: "وقت الوصول المتوقع",
          enter_email: "يرجى إدخال البريد الإلكتروني",
          type_your_requests_here: "يرجى كتابة طلباتك هنا",
          add_special_requests: "إضافة طلبات خاصة",
          we_also_provide: "نحن نوفر أيضًا",
          cancellation_policy: "سياسة الإلغاء",
          date: "التاريخ",
          refund: "استرداد",
          refund_policy_description:
            "إذا كان المبلغ المدفوع أقل من المبلغ المسترد، فلن يتم أي استرداد عند الإلغاء. قد يستغرق استرداد المبلغ ما يصل إلى 4-5 أيام عمل",
          before: "قبل",
          why_book_direct: "فوائد الحجز المباشر",
          disclaimer: "تنبيه",
          what_went_wrong: "ما الخطأ الذي حدث؟",
          payment_options_message:
            "نحن نقدم جميع أنواع خيارات الدفع بما في ذلك الأقساط والمحافظ الإلكترونية مثل أي منصة أخرى",
          booking_directly_message:
            "الحجز مباشرة معنا يوفر عمولات مفقودة، مما يعود بالفائدة مباشرة على الفندق وموظفينا الذين هم العمود الفقري للأعمال الصغيرة.",
          book_directly_message:
            "احجز مباشرة معنا للحصول على أعلى مستوى من الخدمة",
          share_feedback: "شارك رأيك للحصول على فرصة لـ",
          additional_discount_expires_in: "الخصم الإضافي ينتهي خلال",
          win_voucher: "اربح قسيمة أمازون بقيمة ₹500",
          improvement_feedback: "دعنا نعرف كيف يمكننا تحسين خدماتنا",
          limited_offer: "عرض لفترة محدودة!",
          click_here: "اضغط هنا الآن!",
          hourly_room_booking: "حجز الغرف بالساعة",
          no_waiting_just_go: "لا داعي للانتظار، انطلق!",
          instant_confirmation: "تأكيد فوري",
          on_bookings_above: "على الحجوزات التي تزيد قيمتها عن",
          get: "احصل على",
          flat: "وحدة سكنية",
          and_get: "واحصل على",
          early_bird_offer: "عرض الحجز المبكر",
          use_code: "استخدم الكود",
          book: "احجز",
          last_minute_deals: "عروض اللحظة الأخيرة",
          days_advance: "قبل عدة أيام",
          book_now_for: "احجز الآن لـ",
          go_back: "عودة",
          room_details: "تفاصيل الغرفة",
          page_not_found: "لم يتم العثور على الصفحة",
          above_12_years_age: "أكبر من 12 عامًا",
          select_guests: "اختر النزلاء",
          below_12_years_age: "أقل من 12 عامًا",
          done: "تم",
          fill_details_generate_itinerary:
            "أكمل البيانات التالية لإنشاء جدول سفر مُخصص لرحلتك",
          plan_up_to_3_days: "يمكنك التخطيط لمدة تصل إلى 3 أيام كحد أقصى",
          fill_all_details: "يرجى ملء جميع التفاصيل",
          maximum_additional_guest: "الحد الأقصى للضيوف الإضافيين:",
          room_minimum_capacity: "الحد الأدنى لعدد الأشخاص في الغرفة:",
          no_worries_cancel: "لا داعي للقلق. إلغاء بسهولة ويسر",
          flexible_hassle_free_affordable: "مرنة. بدون عناء. بأسعار معقولة.",
          wait_create_itinerary:
            "نرجو الانتظار حيث نقوم بإعداد جدول السفر الخاص بك",
          generate_travel_itinerary: "إعداد جدول سفر",
          total: "الإجمالي",
          secured_payments: "مدفوعات مؤمَّنة بنسبة %100",
          email: "البريد الإلكتروني",
          generate_itinerary: "إعداد جدول سفر",
          planning_days: "كم يومًا تخطط للسفر؟",
          enter_number_of_days: "أدخل عدد الأيام",
          purpose_of_visit: "ما هو الغرض من الزيارة؟",
          visit_purposes: "عطلة، ترفيه، المأكولات الشهية، وما إلى ذلك",
          personalized_itinerary: "إليك جدول الرحلة الخاص بك.",
          book_now_savor_itinerary: "احجز الآن، استمتع بالبرنامج !",
          book_direct_best_price_service:
            "احجز مباشرةً: أفضل سعر مع خدمة استثنائية.",
          subscribe_now_to: "اشترك الآن في",
          get_extra: "احصل على....... إضافي",
          subscribe: "اشترك",
          no_thanks: "لا، شكرًا!",
          add_for: "أضف لـ",
          is_selected_for: "تم اختياره لـ",
          days: "أيام",
          powered_by: "مدعوم من",
          copyrights: "حقوق النشر",
          privacy_policy: "سياسة الخصوصية",
          terms_and_conditions: "الشروط و الأحكام",
          made_with_love_in_india: "صُنع بـ ❤️ في الهند",
          all_rights_reserved: "جميع الحقوق محفوظة",
          please_reach_us_at: "يرجى التواصل معنا على",
          ask_a_question: "هل لديك سؤال؟",
          applied: "تم تطبيقه",
          for: "لـ",
          enter_valid_phone_number: "يرجى إدخال رقم هاتف صحيح",
          upto: "حتى",
          nights: "ليالي",
          booking_support_local:
            "بحجزكم مباشرةً، تساهمون في دعم فندقنا المحلي والمجتمع المحلي. انضموا إلينا لنخلق تأثير إيجابي ونعيش تجربة حقيقية للضيافة المحلية",
          you_got: "حصلت على",
          enhanced_experience: "تجربة مطوّرة",
          first_choice_preference: "الاختيار الأول المفضل",
          book_directly_lowest_rates:
            "احجز مباشرة للحصول على أدنى سعر . استمتع بأفضل قيمة مقابل سعر عند الحجز من خلال موقعنا",
          booking_directly_saves:
            "من خلال الحجز مباشرة معنا، نوفر رسوم العمولة بنسبة 20% وبالتالي نقدم لك تجربة أفضل",
          book_directly_hassle_free:
            "احجز مباشرةً عبر موقعنا الرسمي للفندق واستمتع بتجربة سهلة مع فوائد لا مثيل لها!",
          best_price_guarantee: "ضمان أفضل سعر",
          support_local_businesses: "دعم الأعمال المحلية",
          booking_benefits: "فوائد الحجز من موقعنا الرسمي",
          please_enter_your_question: "الرجاء إدخال سؤالك",
          select_at_least_one_room: "يرجى اختيار غرفة واحدة على الأقل",
          additional_15_percent_off_applied: "تم تطبيق خصم إضافي بنسبة 15%!",
          book_direct_choose_room:
            "احجز مباشرة لامتياز اختيار غرفتك المثالية أولاً.",
          lets_book: "هيا بنا نحجز",
          help_us_know_more: "ساعدنا في معرفة المزيد!",
          get_best_price: "احصل على أفضل سعر!",
          submit: "أرسل ",
          enter_booking_id: "الرجاء إدخال رقم الحجز",
          on_google: "على جوجل",
          check_inbox_spam_folder:
            "يرجى التحقق من صندوق الوارد ومجلد الرسائل غير المرغوب فيها للحصول على قسيمة الحجز التي أرسلناها عبر البريد الإلكتروني والرسائل النصية. يرجى التواصل معنا إذا لم تتلقَّ تحديثًا خلال 6 ساعات",
          better_price_elsewhere: "هل وجدت سعرًا أفضل في مكان آخر؟",
          share_details_best_deal:
            "شارك تفاصيلك للحصول على أفضل عرض! سيقوم فريقنا بالتواصل",
          best_price_guarantee_chat: "اتصال فيديو لمعاينة الغرفة!",
          apology_incorrect_price:
            "نعتذر بصدق عن الإزعاج الناجم عن عرض سعر غير صحيح . كانت حادثة غير محظوظة بسبب الطلب الكبير وخلل فني. نأسف بشدة على أي إزعاج ونرغب في تصحيح الأمور لك",
          booking_cancelled_successfully: "تم إلغاء الحجز بنجاح.",
          where_lower_price: "من أين حصلت على العرض الأفضل؟",
          booking_request_received: "لقد تلقينا طلب حجزك!",
          complete_booking: "إتمام الحجز",
          booking_confirmed: "تم تأكيد الحجز!",
          booking_rejected: "تم رفض الحجز!",
          booking_cancelled: "تم إلغاء الحجز!",
          waiting_to_host_your_stay: "في انتظار استضافتكم :)",
          enjoy_your_stay: "تهانينا! استمتع بإقامتك :)",
          stays_not_available: "عذرًا، لا تتوفر إقامة للأيام المختارة",
          booking_cancelled_message: "لقد قمتَ بإلغاء حجزك",
          refund_initiated: "تم بدء عملية استرداد المبلغ (إذا كانت مطبقة)",
          booking_cancelled_notification: "تم إلغاء حجزك",
          refund_initiated_notification:
            "و تم بدء عملية الاسترداد (إذا كانت مطبقة)",
          booking_details: "تفاصيل الحجز",
          contact_us: "تواصل معنا",
          room_and_guest: "الغرفة والنزيل",
          child: "طفل",
          infant: "رضيع",
          payment_status: "حالة الدفع",
          decline_price_refund_note:
            "ملاحظة: إذا قررت رفض السعر المعدل، سنقوم بمعالجة استرداد أي مبلغ قمت بدفعه بالفعل خلال 3-5 أيام عمل.",
          view_details: "عرض التفاصيل",
          updated_price: "تحديث السعر",
          amount_to_pay: "المبلغ المطلوب للدفع (شامل الضرائب)",
          advance_paid: "المبلغ المدفوع مقدمًا",
          location: "الموقع",
          open_in_maps: "انظر الخريطة",
          payment_options: "خيارات الدفع",
          now_to_reserve: "حجز الآن",
          reject_action: "رفض ",
          call_hotel: "اتصل بالفندق",
          accept_action: "قبول",
          go_home: "اذهب إلى الصفحة الرئيسية",
          refund_details: "تفاصيل الاستراداد",
          booking_id: "رقم الحجز",
          disclaimer_text:
            'تُنشئ Hotelzify Private Limited ("Hotelzify") وتُدير هذا الموقع عبر الإنترنت نيابةً عن الفنادق وتوفر دعمًا تقنيًا فقط لحجز فنادق معينة. جميع المعلومات المتاحة على الموقع هي وفقًا للمعلومات المقدمة من قبل الفنادق إلى Hotelzify وللرجوع إليها فقط. يجب على المستخدم رفع أية تناقضات أو تكاليف أو خسائر أو أضرار أو دعاوى أو عجز أو مطالبات أو نفقات (بما في ذلك تكاليف المحكمة ورسوم المحامين العادلة) فيما يتعلق أو ناتجًا عن أو نتيجة أو ذات صلة بهذا الموقع أو الحجوزات المُجراة عليه مع الفنادق مباشرة. لن يتحمل Hotelzify أي مسؤولية في عملية الحل ولا يتحمل أي مسؤولية عن مثل هذه التناقضات و/أو الخسائر.',
          age_optional: "العمر (اختياري)",
          payment_methods: "طرق الدفع",
          proceed: "متابعة",
          change_language: "تغيير اللغة",
          booking_expired_or_not_exist:
            "الحجز إما منتهي الصلاحية أو غير موجود. يرجى محاولة إنشاء حجز جديد مرة أخرى",
          invalid_date_redirect:
            "تاريخ غير صالح! يتم إعادة توجيهك إلى الصفحة الرئيسية",
          cashback_offer_mobiqwik: "احصل على كاش باك قدره 50 !",
          use_mobikwik: "ادفع باستخدام محفظة Mobikwik / UPI",
          "Video call to see your room": "اتصال فيديو لمعاينة الغرفة!",
          "Call Property": "اتصل بالفندق",
        },
      },
      hi: {
        translation: {
          modify_booking_title: "बुकिंग शीर्षक संशोधित करें",
          "before arrival incurs a": "आगमन से पहले होता है",
          Cancelling: "रद्द करना",
          penalty: "दंड",
          "Incase of No Show / Cancelling on day of Checkin will incur":
            "नो शो / चेकइन के दिन रद्द करने पर शुल्क लगेगा",
          Available: "उपलब्ध",
          "Promotion Applied": "प्रमोशन लागू किया गया",
          "Maximum room count reached!": "अधिकतम कमरे की संख्या पहुंच गई!",
          select_room: "कमरा चुनें",
          booking_summary: "बुकिंग सारांश",
          full_night_booking: "पूर्ण रात बुकिंग",
          hourly_booking: "घंटे के हिसाब से बुकिंग",
          unlock: "अनलॉक करें",
          remove: "हटाएं",
          bed: "बिस्तर",
          room: "कमरा",
          night: "रात",
          guests: "मेहमान",
          guest: "मेहमान",
          hour: "घंटा",
          addons: "एडऑन",
          discount: "छूट",
          get_property_website:
            "अपनी संपत्ति वेबसाइट प्राप्त करने के लिए यहां क्लिक करें",
          unlock_additional_discount: "अतिरिक्त 15% छूट अनलॉक करें!",
          code_mount_applied: "कोड MOUNT लागू",
          additional_15_off: "अतिरिक्त ऑफर",
          final_price: "अंतिम मूल्य",
          taxes: "कर",
          book_now: "अभी बुक करें",
          hello_there: "नमस्ते!",
          im: "मैं हूँ",
          travel_dates_and_guests: "यात्रा तिथियाँ और मेहमान",
          your_host: "आपका मेजबान",
          overview: "समीक्षा",
          rooms: "कक्ष",
          faqs: "सामान्य प्रश्न",
          reviews: "समीक्षाएँ",
          show_more: "और दिखाएँ",
          show_all: "सभी दिखाएँ",
          map: "नक्शा",
          facilities: "सुविधाएँ",
          unmarried_couples_not_allowed: "अविवाहित जोड़े की अनुमति नहीं है",
          amenities: "सुविधाएँ",
          introducing_hourly_stays: "घंटे के हिसाब से ठहराव की शुरुआत!",
          show_less: "कम दिखाएँ",
          book_for: "बुक करें",
          best_service_offers:
            "हमसे सीधे बुक करें सर्वोत्तम सेवा और ऑफर के लिए!",
          about_us: "हमारे बारे में",
          book_for_full_night: "पूर्ण रात के लिए बुक करें",
          nearby_places: "निकटवर्ती स्थान",
          view_in_maps: "नक्शे में देखें",
          couple_friendly:
            "जोड़े के अनुकूल। एक सुरक्षित और अंतरंग प्रवास का अनुभव करें।",
          slay_travel_itinerary: "यात्रा कार्यक्रम को बुक करें",
          generate_in_seconds: "कुछ ही सेकंड में उत्पन्न करें",
          cancel_booking: "अपनी बुकिंग रद्द करें",
          verify: "सत्यापित करें",
          amount_paid: "भुगतान की गई राशि",
          total_amount: "कुल राशि",
          cancel_confirmation: "क्या आप वाकई रद्द करना चाहते हैं?",
          refund_amount: "वापसी राशि",
          no_option: "नहीं",
          no_booking_found: "क्षमा करें, इस संख्या के लिए कोई बुकिंग नहीं मिली",
          yes_option: "हाँ",
          property_rules_title: "संपत्ति नियम",
          selected_date: "चयनित तिथि",
          select: "चुनें",
          faq_title: "सामान्य प्रश्न",
          existing_booking_cancel: "क्या आप मौजूदा बुकिंग रद्द करना चाहते हैं?",
          cancellation_refund_policy: "रद्दीकरण / वापसी नीति",
          if_cancelled: "यदि रद्द किया गया",
          deducted: "कुल राशि का काटा जाएगा।",
          free_cancellation: "मुफ्त रद्दीकरण उपलब्ध",
          follow_us_on: "हमें फॉलो करें",
          offer_ends_soon:
            "ऑफ़र जल्द ही समाप्त होगा, ऑफ़र प्राप्त करने के लिए अभी बुक करें",
          continue: "जारी रखें",
          checkin_date_time: "चेक-इन तिथि - चेक-इन समय",
          checkin_checkout: "चेक-इन - चेक-आउट",
          not_available_selected_checkin_time:
            "चयनित चेक-इन समय के लिए उपलब्ध नहीं है",
          please_select_time_slot: "कृपया समय स्लॉट चुनें",
          reserve: "आरक्षित करें",
          adult_age: "18 वर्ष और उससे अधिक",
          adults: "वयस्क",
          years: "वर्ष",
          children: "बच्चे",
          infants: "शिशु",
          selected_for: "के लिए चयनित",
          maximum_occupancy_reached:
            "अधिकतम क्षमता पहुँच गई, अतिरिक्त कमरे का चयन करें।",
          guest_select_stay_for_other:
            "मेहमान, कृपया अन्य के लिए ठहराव का चयन करें",
          extra_adult: "अतिरिक्त वयस्क",
          extra_child: "अतिरिक्त बच्चा",
          price_for_upto: "तक के लिए कीमत",
          price_for: "के लिए कीमत",
          max_bed: "अधिकतम बिस्तर",
          bed_dorm: "बिस्तर डॉर्म",
          additional_guests: "अतिरिक्त मेहमान (शुल्क लागू)",
          max: "अधिकतम",
          max_pax: "अधिकतम पेक्स",
          included: "शामिल",
          free: "मुफ्त",
          verified: "सत्यापित",
          can_stay_for_free: "मुफ्त में ठहर सकते हैं",
          lowest_price_ever: "अब तक का सबसे कम मूल्य",
          recommended: "अनुशंसित",
          min_pax: "न्यूनतम पेक्स",
          free_cancellation_till: "तब तक मुफ्त रद्दीकरण",
          book_now_pay_later: "अभी बुक करें, बाद में भुगतान करें!",
          reserve_at_just: "बस में आरक्षित करें",
          sold_out: "बिक गया",
          room_left_hurry_up: "कमरा बचा है, जल्दी करें!",
          off: "छूट",
          incl_of_taxes: "कर सहित",
          select_rooms: "कमरे चुनें",
          add: "जोड़ें",
          enter_email_address: "कृपया ईमेल पता दर्ज करें",
          view_room: "कमरा देखें",
          selected: "चयनित",
          person: "व्यक्ति",
          enter_valid_email_address: "कृपया मान्य ईमेल पता दर्ज करें",
          day: "दिन",
          enter_name: "कृपया मान्य नाम दर्ज करें",
          enter_all_details: "कृपया सभी विवरण दर्ज करें",
          please_select_payment_method: "कृपया भुगतान विधि चुनें",
          try_again_later: "कृपया बाद में पुनः प्रयास करें!",
          thank_you_response: "आपके प्रतिक्रिया के लिए धन्यवाद :)",
          checkout_summary: "चेकआउट सारांश",
          check_in: "चेक-इन",
          room_and_guests: "कमरा और मेहमान",
          check_out: "चेक-आउट",
          secured_by: "द्वारा सुरक्षित",
          more: "& अधिक",
          pay_at_property: "संपत्ति पर भुगतान करें",
          pay_just: "बस भुगतान करें",
          pay_full_amount: "पूरी राशि का भुगतान करें",
          to_book: "बुक करने के लिए",
          book_now_pay_later: "अभी बुक करें, बाद में भुगतान करें",
          cancel_booking_prompt: "बुकिंग रद्द करना चाहते हैं?",
          bill_details: "बिल विवरण",
          additional_discount: "अतिरिक्त छूट",
          select_payment_method: "भुगतान विधि चुनें",
          cashback_offer: "ऑनलाइन भुगतानों पर ₹50 तक कैशबैक",
          add_reason_suggestions: "कृपया अपना कारण / सुझाव जोड़ें",
          hurry_up_discount_expires_in:
            "जल्दी करें! अतिरिक्त छूट समाप्त हो रही है",
          selected_rooms: "चयनित कमरे",
          pax_but_rooms_selected_for_only:
            "पेक्स लेकिन केवल के लिए कमरे का चयन किया गया है",
          you_have_searched_for: "आपने खोजा है",
          note: "नोट:",
          please_book_extra_rooms_for_other:
            "कृपया अन्य के लिए अतिरिक्त कमरे बुक करें",
          traveller_details: "यात्री विवरण",
          contact_number: "संपर्क नंबर",
          enter_contact_number: "संपर्क नंबर दर्ज करें",
          full_name: "पूरा नाम",
          enter_traveller_name: "यात्री का नाम दर्ज करें",
          enter_age: "आयु दर्ज करें",
          enter_email_id: "ईमेल आईडी",
          estimated_arrival_time: "अनुमानित आगमन समय",
          enter_email: "ईमेल दर्ज करें",
          type_your_requests_here: "यहां अपनी अनुरोध लिखें",
          add_special_requests: "विशेष अनुरोध जोड़ें",
          we_also_provide: "हम यह भी प्रदान करते हैं",
          cancellation_policy: "रद्दीकरण नीति",
          date: "तिथि",
          refund: "वापसी",
          refund_policy_description:
            "यदि भुगतान की गई राशि वापसी राशि से कम है, तो रद्द करने पर कोई वापसी जारी नहीं की जाएगी। वापसी प्रक्रिया में 4-5 कार्यदिवस लग सकते हैं।",
          before: "पहले",
          why_book_direct: "सीधे क्यों बुक करें?",
          disclaimer: "अस्वीकरण",
          what_went_wrong: "क्या गलत हुआ?",
          payment_options_message:
            "हम सभी प्रकार के भुगतान विकल्प प्रदान करते हैं जिसमें ईएमआई और वॉलेट शामिल हैं",
          booking_directly_message:
            "हमसे सीधे बुक करने से कमीशन की बचत होती है, जिससे हमारी संपत्ति और स्टाफ को लाभ होता है",
          book_directly_message:
            "हमसे सीधे बुक करें सबसे अच्छी सेवा गुणवत्ता के लिए",
          share_feedback: "फीडबैक साझा करें और मौका पाएं",
          additional_discount_expires_in: "अतिरिक्त छूट समाप्त हो रही है",
          win_voucher: "₹500 का अमेज़न वाउचर जीतें",
          improvement_feedback: "हमें सुधार के सुझाव दें",
          limited_offer: "सीमित समय का ऑफर!",
          click_here: "अभी क्लिक करें!",
          hourly_room_booking: "घंटे के हिसाब से कमरे की बुकिंग",
          no_waiting_just_go: "कोई इंतजार नहीं, बस जाएं!",
          instant_confirmation: "तुरंत पुष्टि",
          on_bookings_above: "से ऊपर की बुकिंग पर",
          get: "प्राप्त करें",
          flat: "फ्लैट",
          and_get: "और प्राप्त करें",
          early_bird_offer: "जल्दी बुकिंग का ऑफर",
          use_code: "कोड का उपयोग करें",
          book: "बुक",
          last_minute_deals: "अंतिम समय के सौदे",
          days_advance: "दिन पहले",
          book_now_for: "बुक करें",
          go_back: "वापस जाएं",
          room_details: "कमरे का विवरण",
          page_not_found: "पृष्ठ नहीं मिला",
          above_12_years_age: "12 वर्ष से ऊपर की उम्र",
          select_guests: "मेहमान चुनें",
          below_12_years_age: "12 वर्ष से कम की उम्र",
          done: "हो गया",
          fill_details_generate_itinerary:
            "अपनी यात्रा के लिए व्यक्तिगत यात्रा कार्यक्रम उत्पन्न करने के लिए निम्नलिखित विवरण भरें",
          plan_up_to_3_days: "आप केवल 3 दिनों की योजना बना सकते हैं",
          fill_all_details: "कृपया सभी विवरण भरें",
          maximum_additional_guest: "अधिकतम अतिरिक्त मेहमान:",
          room_minimum_capacity: "कमरे की न्यूनतम क्षमता:",
          no_worries_cancel: "कोई चिंता नहीं। बिना झंझट के रद्द करें।",
          flexible_hassle_free_affordable: "लचीला। बिना झंझट के। किफायती।",
          wait_create_itinerary:
            "कृपया प्रतीक्षा करें जबकि हम आपकी व्यक्तिगत यात्रा कार्यक्रम बना रहे हैं",
          generate_travel_itinerary: "यात्रा कार्यक्रम उत्पन्न करें",
          total: "कुल",
          sold_out: "बिक गया",
          secured_payments: "100% सुरक्षित भुगतान",
          email: "ईमेल",
          generate_itinerary: "यात्रा कार्यक्रम उत्पन्न करें",
          planning_days: "आप कितने दिनों के लिए योजना बना रहे हैं?",
          enter_number_of_days: "दिनों की संख्या दर्ज करें",
          purpose_of_visit: "यात्रा का उद्देश्य क्या है?",
          visit_purposes: "छुट्टियाँ, मनोरंजन, पाक आनंद आदि",
          personalized_itinerary: "यहां आपकी व्यक्तिगत यात्रा कार्यक्रम है",
          book_now_savor_itinerary:
            "अभी बुक करें, यात्रा कार्यक्रम का आनंद लें!",
          book_direct_best_price_service:
            "सीधे बुक करें: सर्वोत्तम मूल्य, उत्कृष्ट सेवा।",
          subscribe_now_to: "अभी सब्सक्राइब करें",
          get_extra: "अतिरिक्त प्राप्त करें",
          subscribe: "सब्सक्राइब",
          no_thanks: "नहीं, धन्यवाद!",
          add_for: "के लिए जोड़ें",
          is_selected_for: "के लिए चयनित है",
          days: "दिन",
          powered_by: "द्वारा संचालित",
          copyrights: "कॉपीराइट",
          privacy_policy: "गोपनीयता नीति",
          terms_and_conditions: "नियम और शर्तें",
          made_with_love_in_india: "❤️ के साथ भारत में बना",
          all_rights_reserved: "सर्वाधिकार सुरक्षित",
          please_reach_us_at: "कृपया हमसे संपर्क करें",
          ask_a_question: "एक प्रश्न पूछें?",
          applied: "लागू",
          for: "के लिए",
          enter_valid_phone_number: "मान्य फोन नंबर दर्ज करें",
          upto: "तक",
          nights: "रातें",
          per_night: "प्रति रात",
          booking_support_local:
            "सीधे बुकिंग करके, आप हमारे स्थानीय होटल और समुदाय का समर्थन करते हैं। हमारे साथ जुड़ें और वास्तविक स्थानीय आतिथ्य का अनुभव करें।",
          you_got: "आपको मिला",
          enhanced_experience: "उन्नत अनुभव",
          first_choice_preference: "पहली पसंद प्राथमिकता",
          book_directly_lowest_rates:
            "हमारे वेबसाइट से सीधे बुक करें सबसे कम दरों के लिए।",
          booking_directly_saves:
            "सीधे बुकिंग करके हम 20% कमीशन चार्ज की बचत कर सकते हैं और आपको बेहतर अनुभव प्रदान कर सकते हैं।",
          book_directly_hassle_free:
            "हमारी आधिकारिक होटल वेबसाइट से सीधे बुक करें, बिना किसी झंझट के और बेजोड़ लाभ के लिए!",
          best_price_guarantee: "सर्वोत्तम मूल्य गारंटी",
          support_local_businesses: "स्थानीय व्यवसायों का समर्थन करें",
          booking_benefits: "हमारी आधिकारिक वेबसाइट से बुकिंग के लाभ",
          please_enter_your_question: "कृपया अपना प्रश्न दर्ज करें",
          select_at_least_one_room: "कृपया कम से कम एक कमरा चुनें",
          additional_15_percent_off_applied: "अतिरिक्त 15% छूट लागू!",
          book_direct_choose_room:
            "सीधे बुक करें और अपने आदर्श कमरे का चयन करें।",
          lets_book: "चलो बुक करें",
          help_us_know_more: "हमें और अधिक जानने में मदद करें!",
          get_best_price: "सर्वोत्तम मूल्य प्राप्त करें!",
          submit: "जमा करें",
          enter_booking_id: "कृपया बुकिंग आईडी दर्ज करें",
          on_google: "गूगल पर",
          check_inbox_spam_folder:
            "हमारे द्वारा भेजे गए बुकिंग वाउचर के लिए अपने इनबॉक्स और स्पैम फ़ोल्डर की जांच करें। यदि आपको 6 घंटे के भीतर कोई अपडेट नहीं मिलता है तो हमसे संपर्क करें।",
          better_price_elsewhere: "कहीं और बेहतर मूल्य मिला?",
          share_details_best_deal:
            "सर्वोत्तम सौदे के लिए अपने विवरण साझा करें! हमारी टीम आपसे संपर्क करेगी।",
          best_price_guarantee_chat: "अपने कमरे को देखने के लिए वीडियो कॉल!",
          apology_incorrect_price:
            "हमारी वेबसाइट पर आपकी बुकिंग के लिए दिखाए गए गलत मूल्य के कारण हुई असुविधा के लिए हम ईमानदारी से माफी मांगते हैं। यह उच्च मांग और तकनीकी गड़बड़ी के कारण हुआ दुर्भाग्यपूर्ण घटना थी। हम किसी भी असुविधा के लिए गहरा खेद व्यक्त करते हैं और इसे सही करना चाहते हैं।",
          booking_cancelled_successfully: "बुकिंग सफलतापूर्वक रद्द कर दी गई",
          where_lower_price: "आपने कम मूल्य कहां पाया?",
          booking_request_received: "हमने आपकी बुकिंग अनुरोध प्राप्त किया है!",
          complete_booking: "अपनी बुकिंग पूरी करें!",
          booking_confirmed: "बुकिंग की पुष्टि हो गई!",
          booking_rejected: "बुकिंग अस्वीकृत!",
          booking_cancelled: "बुकिंग रद्द!",
          waiting_to_host_your_stay: "आपकी ठहराव की प्रतीक्षा कर रहे हैं :)",
          enjoy_your_stay: "बधाई हो! अपने ठहराव का आनंद लें :)",
          stays_not_available:
            "क्षमा करें, चयनित दिनों के लिए ठहराव उपलब्ध नहीं हैं।",
          booking_cancelled_message: "आपने अपनी बुकिंग रद्द कर दी है",
          refund_initiated: "वापसी शुरू कर दी गई है। (यदि लागू हो)",
          booking_cancelled_notification: "आपकी बुकिंग रद्द कर दी गई है",
          refund_initiated_notification:
            "और वापसी शुरू कर दी गई है। (यदि लागू हो)",
          booking_details: "बुकिंग विवरण",
          contact_us: "हमसे संपर्क करें",
          check_in: "चेक-इन",
          room_and_guest: "कक्ष और अतिथि",
          child: "बच्चा",
          infant: "शिशु",
          payment_status: "भुगतान स्थिति",
          decline_price_refund_note:
            "नोट: यदि आप संशोधित मूल्य को अस्वीकार करने का निर्णय लेते हैं, तो हम किसी भी राशि के लिए वापसी प्रक्रिया करेंगे जो आपने पहले ही 3-5 कार्यदिवसों में भुगतान कर दी है।",
          view_details: "विवरण देखें",
          updated_price: "अद्यतन मूल्य",
          amount_to_pay: "भुगतान की राशि (सभी करों सहित)",
          advance_paid: "अग्रिम भुगतान",
          location: "स्थान",
          open_in_maps: "नक्शे में खोलें",
          payment_options: "भुगतान विकल्प",
          now_to_reserve: "अभी आरक्षित करें",
          reject_action: "अस्वीकार करें",
          call_hotel: "होटल को कॉल करें",
          accept_action: "स्वीकार करें",
          complete_booking: "बुकिंग पूरी करें",
          go_home: "घर जाएं",
          refund_details: "वापसी विवरण",
          booking_id: "बुकिंग आईडी",
          disclaimer_text:
            'होटलज़िफाई प्राइवेट लिमिटेड ("होटलज़िफाई") संपत्ति की ओर से इस वेबसाइट का निर्माण और रखरखाव करता है और केवल एक विशिष्ट संपत्ति के लिए बुकिंग सक्षम करने के लिए तकनीकी समर्थन प्रदान करता है। वेबसाइट पर उपलब्ध सभी जानकारी संपत्ति द्वारा होटलज़िफाई को प्रदान की गई जानकारी के अनुसार है और केवल संदर्भ के लिए है। कोई भी विसंगतियाँ, या लागत, हानि, क्षति, मुकदमे, कमी, दावे और खर्च (कोर्ट लागत और उचित वकील की फीस सहित) जो इस वेबसाइट या उस पर की गई बुकिंग के कारण उत्पन्न होती हैं, का समाधान उपयोगकर्ता सीधे संपत्ति के साथ करेगा। होटलज़िफाई इस प्रक्रिया में कोई जिम्मेदारी नहीं लेगा और ऐसी विसंगतियों और/या हानियों के लिए किसी भी उत्तरदायित्व को स्वीकार नहीं करेगा।',
          age_optional: "आयु (वैकल्पिक)",
          payment_methods: "भुगतान विधियाँ",
          proceed: "आगे बढ़ें",
          change_language: "भाषा बदलें",
          booking_expired_or_not_exist:
            "बुकिंग या तो समाप्त हो गई है या अस्तित्व में नहीं है। कृपया फिर से बुकिंग बनाने का प्रयास करें।",
          invalid_date_redirect:
            "अमान्य तिथि! होम पेज पर पुनः निर्देशित हो रहे हैं",
          cashback_offer_mobiqwik: "आश्वासन ₹10-50 कैशबैक!",
          use_mobikwik: "मोबिक्विक के माध्यम से अतिरिक्त छूट",
          "Video call to see your room": "अपने कमरे को देखने के लिए वीडियो कॉल",
          "Call Property": "संपत्ति को कॉल करें",
        },
      },
      // Add more languages here
    },
    lng: "en", // default language
    fallbackLng: "en",
    debug: false,

    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
